  <form [formGroup]="form"> <input type='hidden' name='' value='' /> 
    <div class="form-fields-wrapper"> 
        <div *ngFor="let formfield of formfields.fields" class="form-field-container"> 
          <label class="inline" for="inspectionTitle">{{formfield.title}}</label> 
          <input *ngIf=" formfield.type === 'Entry'" class="form-control inline" type="{{formfield.contentType}}" formControlName="{{formfield.name}}" /> 
          <select *ngIf=" formfield.type === 'Select'" class="form-control inline" formControlName="{{formfield.name}}" >
            <option value=""></option>
            <option *ngFor="let value of formfield.values">{{value}}</option>
            </select>
        </div> 
    </div>   <!-- end form-fields-wrapper -->
    <div class="form-actions button-bar"> 
        <div class="submit-row"> 
            <button style="width: 46%" type="cancel" name="cancel" value="Abbrechen" class="btn btn clear" id="submit-id-submit">Abbrechen</button>
            <button style="width: 46%" type="submit" name="submit" value="Speichern" class="btn btn-primary" id="btn-user-info-save">Prüfungsdaten speichern</button>
        </div>
      </div>   <!-- end form-actions -->
    <input type="hidden" name="ignore_warnings" value="False" id="id_ignore_warnings" /> 
  </form>

