
  <div class="body">
    <div class="signature-container">
      <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
    </div>
    <!--
    <div class="signature-image" *ngIf="signatureImg">
      <img src='{{ signatureImg }}' />
    </div>-->
  </div>
  <div class="actions">
    <button class="btn btn clear" (click)="savePad()">Unterschrift übernehmen</button>
    <button class="btn btn clear" (click)="clearSignature()">Löschen</button>
    
    <button (click)="cancelModal('signatureModal')" class="btn btn clear">Abbrechen</button>
  </div>   <!-- end form-actions -->

