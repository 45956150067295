import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TabsComponent } from './components/core/tabs/tabs.component';
import { TabComponent } from './components/core/tab/tab.component';
import { UserHasRoleDirective } from './directives/userHasRole.directive';
import { UploadComponent } from './components/core/upload/upload.component';
import { DownloadComponent } from './components/core/download/download.component';
import { FileManagerComponent } from './components/core/file-manager/file-manager.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SortByKeysPipe } from './pipes/sort-by-keys.pipe';
import { UserHasModuleDirective } from './directives/userHasModule.directive';
import { SignaturePadComponent } from './components/core/signature-pad/signature-pad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DynamicFormComponent } from './components/core/dynamic-form/dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from './modal';
import { ImageBoxComponent } from './components/core/image-box/image-box.component';
import { ImageViewerComponent } from './components/core/image-viewer/image-viewer.component';
import { CopyClipboardDirective } from './directives/copyClipboard';

const COMPONENTS = [
  TabsComponent,
  TabComponent,
  ImageBoxComponent,
  ImageViewerComponent,
  UploadComponent,
  DownloadComponent,
  FileManagerComponent,
  SignaturePadComponent,
  DynamicFormComponent
];

const DIRECTIVES = [
  CopyClipboardDirective,
  UserHasRoleDirective,
  UserHasModuleDirective,
];

const MODULES = [
  CommonModule,
  SignaturePadModule,
  FormsModule,
  ModalModule,
  ReactiveFormsModule
];

const PIPES = [
  SortByPipe,
  SortByKeysPipe,
];

const SERVICES = [
];

@NgModule({
  imports: [
    ...MODULES,
  ],
  declarations: [
    ...PIPES,
    ...DIRECTIVES,
    ...COMPONENTS,
  ],
  exports: [
    ...PIPES,
    ...DIRECTIVES,
    ...COMPONENTS,

  ],
  providers: [
    ...PIPES,
    ...SERVICES,
    DatePipe,
   ],
})
export class GlobalUseModule { }
