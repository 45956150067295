import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {

constructor() { }

expandTableSize(col1: string, col2: string, cols: number = 12) {
  const column1 = document.getElementById(col1);
  const column2 = document.getElementById(col2);
  // remove all classes
  column1.className = '';
  column2.className = '';
  // add class lists

  column1.classList.add('col-xs-' + cols.toString());
  column2.classList.add('col-xs-0');
}

reduceTableSize(col1: string, col2: string) {
  const column1 = document.getElementById(col1);
  const column2 = document.getElementById(col2);
  // remove all classes
  column1.className = '';
  column2.className = '';
  // add class lists
  column1.classList.add('col-xs-12', 'col-sm-7', 'col-md-7', 'col-lg-8');
  column2.classList.add('col-xs-12', 'col-sm-5', 'col-md-5', 'col-lg-4');
}

reduceTableSize6(col1: string, col2: string) {
  const column1 = document.getElementById(col1);
  const column2 = document.getElementById(col2);
  // remove all classes
  column1.className = '';
  column2.className = '';
  // add class lists
  column1.classList.add('col-xs-12', 'col-sm-6', 'col-md-6', 'col-lg-6');
  column2.classList.add('col-xs-12', 'col-sm-4', 'col-md-4', 'col-lg-4');
}

reduceTableSize9(col1: string, col2: string) {
  const column1 = document.getElementById(col1);
  const column2 = document.getElementById(col2);
  // remove all classes
  column1.className = '';
  column2.className = '';
  // add class lists
  column1.classList.add('col-xs-4');
  column2.classList.add('col-xs-8');
}

reduceTableSize12(col1: string, col2: string) {
  const column1 = document.getElementById(col1);
  const column2 = document.getElementById(col2);
  // remove all classes
  column1.className = '';
  column2.className = '';
  // add class lists
  column1.classList.add('hidden');
  column2.classList.add('col-xs-12');
}

}
