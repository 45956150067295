<div class="pagination">
  <ul class="justify-content-center">

    <li class="page-link" (click)="onPrev()">&lt;</li>

    <li class="page-link"
    *ngFor="let pageNum of getPages()"
    (click)="onPage(pageNum)"
    [ngClass]="{'active': pageNum === page, 'disabled': loading}">{{ pageNum }}</li>

    <li class="page-link" (click)="onNext(true)">&gt;</li>

  </ul>
  <div class="description justify-content-center">
    <span class="page-counts">{{ getMin() }} - {{ getMax() }} of {{ count }}</span>
    <!-- <span class="page-totals">{{ pagesToShow }} pages</span> -->
  </div>
</div>