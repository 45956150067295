import { Component, OnInit, Input } from '@angular/core';
import { TabsComponent } from '../tabs/tabs.component';
import { Tab } from './tab.interface';

@Component({
  selector: 'tm-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent implements OnInit, Tab {

  @Input() tabTitle;
  selected: boolean;

  constructor(private tabsComponent: TabsComponent) {}

  ngOnInit() {
    this.tabsComponent.addTab(this);
  }

}
