import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { Subscription } from 'rxjs';
import { FacilityManagementService } from 'src/app/services/facility-management/facility-management.service';
import { LimsService } from 'src/app/services/lims/lims.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {

  activeDeleteRequestsBadgeCount = 0;
  currentSidebarSubscription: Subscription;
  currentSidebar = 'basic';

  constructor(public authService: AuthService,
              private limsService: LimsService,
              private administrationService: AdministrationService,
              private facilityManagementService: FacilityManagementService,
              private sidebarService: SidebarService) { }

  ngOnInit() {
    this.currentSidebarSubscription = this.sidebarService.currentSidebar$.subscribe(value => {
      this.currentSidebar = value;
    });
    // this.countAllDeleteRequestItems();
  }

  ngOnDestroy() {
    this.currentSidebarSubscription.unsubscribe();
  }

  countAllDeleteRequestItems() {
    this.administrationService.countAllDeleteRequests(false).subscribe((x: number) => {
      this.activeDeleteRequestsBadgeCount = x;
    }, err => {
      this.activeDeleteRequestsBadgeCount = 0;
      console.log('Error: (countAllDeleteRequestItems)' + err);
    });
  }

  showFinishedFacilityManagementTasks(value: boolean) {
    console.log(value)
    this.facilityManagementService.setShowFinishedTasks(value)
  }

  showFinishedAnalyseauftraege(value: boolean) {
    console.log(value)
    this.limsService.setShowFinishedAnalyseauftraege(value)
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  logout() {
    this.sidebarService.setCurrentSidebar('basic');
    this.authService.logout();
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }
}
