import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { NotificationService } from '../services/notification/notification.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router,
              private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const roles = next.firstChild.data['roles'] as Array<string>;
    if (roles) {
      const match = this.authService.roleMatch(roles);
      if (match && this.authService.loggedIn()) {
        return true;
      } else {
        this.router.navigate(['']);
        this.notificationService.error('You are not authorized to access this area');
      }
    }
    if (this.authService.loggedIn()) {
      return true;
    }

    this.notificationService.error('Zugriff verweigert!');
    this.router.navigate(['/']);
    return false;
  }
}
