import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectionListItem } from 'src/app/models/selection-list/selectionListItem';

@Injectable({
  providedIn: 'root'
})

export class SelectionListsLocalDataStoreService {

  private selectionListItems = new BehaviorSubject<SelectionListItem[]>([]);

  private dataStore: { selectionListItems: SelectionListItem[] } = { selectionListItems: [] };

  readonly $selectionListItemsObs = this.selectionListItems.asObservable();

constructor() { }

  dataStoreIsEmpty(): boolean {
    if (this.dataStore.selectionListItems.length === 0 ) {
      return true;
    } else {
      return false;
    }
  }

  fillDataStore(items: SelectionListItem[]): void {
    this.dataStore.selectionListItems = items;
  }

  refreshDataStore(): void {
    this.selectionListItems.next(Object.assign({}, this.dataStore).selectionListItems);
  }

  createItem(item: SelectionListItem): void {
    this.dataStore.selectionListItems.push(item);
  }

  updateItem(item: SelectionListItem): void {
    this.dataStore.selectionListItems.forEach((t, i) => {
      if (t.id === item.id) {
        this.dataStore.selectionListItems[i] = item;
      }
    });
  }

  deleteItem(item: SelectionListItem): void {
    this.dataStore.selectionListItems.forEach((t, i) => {
      if (t.id === item.id) {
        this.dataStore.selectionListItems.splice(i, 1);
      }
    });
  }

  getTopics(): Array<any> {
    const list: string[] = [];
    this.dataStore.selectionListItems.forEach(element => {
      list.push(element.itemTopic);
    });
    return Array.from(new Set(list));
  }

  filterItemsByTopic(topic: string): Array<SelectionListItem> {
    return this.dataStore.selectionListItems.filter(el => el.itemTopic.toLowerCase() === topic.toLowerCase());
  }

  filterItemsDependingOnValue(value: string): Array<SelectionListItem> {
    return this.dataStore.selectionListItems.filter(el => el.dependingOnValue.toLowerCase() === value.toLowerCase());
  }

  filterItemsDependingOnTopic(topic: string): Array<SelectionListItem> {
    return this.dataStore.selectionListItems.filter(el => el.dependingOnTopic.toLowerCase() === topic.toLowerCase());
  }
}
