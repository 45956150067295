<div id="dashboard">
  <div *appUserHasRoles="['Lims_Admin', 'Lims_DataManager', 'Lims_User', 'Lims_Guest']"
        class="dashboard-item blue-gradient" 
        routerLink="/lims/lims" 
        routerLinkActive="active">
    <div class="icon-big lims"></div>
    <div class="title">LIMS</div>
  </div>

  <div *appUserHasRoles="['SelectionLists_Admin', 'SelectionLists_DataManager', 'SelectionLists_User']" 
        class="dashboard-item blue-gradient" 
        routerLink="/administration/selection-lists" 
        routerLinkActive="active">
    <div class="icon-big reports"></div>
    <div class="title">Auswahllisten</div>
  </div>
  
  <div *appUserHasRoles="['Inventory_Admin', 'Inventory_DataManager', 'Inventory_User', 'Inventory_Guest']"
        class="dashboard-item blue-gradient" 
        routerLink="/inventory/inventory-overview" 
        routerLinkActive="active">
    <div class="icon-big inventory"></div>
    <div class="title">Inventory</div>
  </div>

  <div *appUserHasRoles="['Inspections_Admin', 'Inspections_DataManager', 'Inspections_User']"
        class="dashboard-item blue-gradient" 
        routerLink="/inspections/inspections-overview" 
        routerLinkActive="active">
    <div class="icon-big inspections"></div>
    <div class="title">Prüfungen</div>
  </div>

  <div *appUserHasRoles="['Administration_Admin', 'Administration_DataManager']"
        class="dashboard-item blue-gradient" 
        routerLink="/administration/delete-requests-overview" 
        routerLinkActive="active">
    <span  *ngIf="administrationBadgeCount > 0" class="badge">{{administrationBadgeCount}}</span>
    <div class="icon-big administration"></div>
    <div class="title">Administration</div>
  </div>
  
  
  <div *appUserHasRoles="['SuperAdmin', 'FacilityManagement_Admin', 'FacilityManagement_DataManager', 'FacilityManagement_User']" 
        class="dashboard-item blue-gradient" 
        routerLink="/facility-management/fm-tasks-overview" 
        routerLinkActive="active">
        <span  *ngIf="facilityManagementBadgeCount > 0" class="badge">{{facilityManagementBadgeCount}}</span>
    <div class="icon-big facility-management"></div>
    <div class="title">Facility Management</div>
  </div>

  <!--<div *appUserHasRoles="['SuperAdmin']" 
        class="dashboard-item blue-gradient" 
        routerLink="/gas-management" 
        routerLinkActive="active">
    <div class="icon-big gas-management"></div>
    <div class="title">Gas Management</div>
  </div>-->
  
  <div *appUserHasRoles="['UserManagement_Admin']"
        class="dashboard-item blue-gradient" 
        routerLink="/administration/user-management" 
        routerLinkActive="active">
    <div class="icon-big user-management"></div>
    <div class="title">Benutzerverwaltung</div>
  </div>

  <!--
  <div *appUserHasRoles="['SuperAdmin']" class="dashboard-item blue-gradient" routerLink="/tenant-management" routerLinkActive="active">
    <div class="icon tenant-management"></div>
    <div class="title">Kundenverwaltung</div>
  </div>
  -->
</div>   <!-- end dashboard -->