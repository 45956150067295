import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-compose',
  templateUrl: './message-compose.component.html'
})
export class MessageComposeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
