import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Directive({
  selector: '[appUserHasRoles]'
})
export class UserHasRoleDirective implements OnInit {
  @Input() appUserHasRoles: string[];
  isVisible = false;

  constructor( private viewContainerRef: ViewContainerRef,
               private templateRef: TemplateRef<any>,
               private authService: AuthService) { }

  ngOnInit() {
    const userRoles = this.authService.decodedToken.role as Array<string>;

    // if no roles clear the viewContainerRef
    if (!userRoles) {
      this.viewContainerRef.clear();
    }

    // if user has role need then render the element
    if (this.authService.roleMatch(this.appUserHasRoles)) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    }
  }

  
}
