import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  form: FormGroup;
  formfields: any = {
    "type": "SpeciticationsForm",
    "topic": "Brenner",
    "fields": [
      {
        "title": "Erstinbetriebnahme",
        "name": "erstinbetriebnahme",
        "type": "Entry",
        "contentType": "text",
        "value": "2015",
        "values": []
      },
      {
        "title": "Nennleistung [kW]",
        "name": "nennleistung",
        "type": "Entry",
        "contentType": "text",
        "value": "180",
        "values": []
      },
      {
        "title": "Brennstoff",
        "name": "brennstoff",
        "type": "Select",
        "contentType": "",
        "value": "Wasserstoff",
        "values": ["Erdgas", "Propan/LPG", "Wasserstoff", "Heizöl", "Schweröl", "Sonstiges"]
      },
      {
        "title": "Oxidator",
        "name": "oxidator",
        "type": "Select",
        "contentType": "",
        "value": "abgereicherte Luft",
        "values": ["abgereicherte Luft", "Luft", "angereicherte Luft", "Sauerstoff"]
      },
      {
        "title": "Sauerstoffquelle",
        "name": "sauerstoffquelle",
        "type": "Select",
        "contentType": "",
        "value": "Wasserstoff",
        "values": ["integriertes Gebläse", "externes Gebläse", "Druckluftnetz", "Druckgaszylinder", "selbstansaugend"]
      },
      {
        "title": "Luft-Brennstoff-Einstellung",
        "name": "luftBrennstoffEinstellung",
        "type": "Select",
        "contentType": "",
        "value": "",
        "values": ["manuell veränderlich", "starr mechanisch", "pneumatisch", "durch Brennersteuerung", "durch Anlagensteuerung"]
      },
      {
        "title": "Max. Vordruck [mbar]",
        "name": "maxVordruck",
        "type": "Entry",
        "contentType": "text",
        "value": "",
        "values": []
      },
      {
        "title": "Min Vordruck [mbar]",
        "name": "minVordruck",
        "type": "Entry",
        "contentType": "text",
        "value": "",
        "values": []
      },
      {
        "title": "max. Betriebsdruck Brennraum",
        "name": "maxBetriebsdruckBrennraum",
        "type": "Entry",
        "contentType": "text",
        "value": "",
        "values": []
      },
      {
        "title": "Betriebsart",
        "name": "betriebsart",
        "type": "Select",
        "contentType": "",
        "value": "",
        "values": ["intermittierend", "Dauerbetrieb > 24h"]
      },
      {
        "title": "Flammenüberwachung",
        "name": "flammenueberwachung",
        "type": "Select",
        "contentType": "",
        "value": "",
        "values": ["Isonisationselektrode", "UV-Diode"]
      },
      {
        "title": "Zündung",
        "name": "zuendung",
        "type": "Select",
        "contentType": "",
        "value": "",
        "values": ["Zündelektrode mit Trafo", "Zündbrenner", "manuell"]
      },
      {
        "title": "Feuerungsautomat Typ",
        "name": "feuerungsautomatTyp",
        "type": "Select",
        "contentType": "",
        "value": "",
        "values": ["Zündelektrode mit Trafo", "Zündbrenner", "manuell"]
      },
      {
        "title": "Datum letzte Prüfung",
        "name": "datumLetztePruefung",
        "type": "Entry",
        "contentType": "text",
        "value": "",
        "values": []
      },
      {
        "title": "Status letzte Prüfung",
        "name": "statusLetztePruefung",
        "type": "Entry",
        "contentType": "text",
        "value": "",
        "values": []
      },
      {
        "title": "Maßnahmen aus Prüfung",
        "name": "maßnahmeAusPruefung",
        "type": "Entry",
        "contentType": "text",
        "value": "",
        "values": []
      }
    ]
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      
    });

    this.formfields.fields.forEach(element => {
      console.log(element.name)
      this.form.addControl(element.name, this.fb.control('', [Validators.required]));
    });

    
    /*this.formfields.fields.forEach(element => {
      this.form.addControl(element.name, new FormControl('', Validators.required));
    });*/
   }

  ngOnInit() {
    
  }

}
