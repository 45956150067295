import { Component, OnInit } from '@angular/core';
import { DeleteRequestItem } from 'src/app/models/administration/delete-request-item.js';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { SelectionListsService } from 'src/app/services/selection-lists/selection-lists.service';
import { FacilityManagementService } from 'src/app/services/facility-management/facility-management.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  administrationBadgeCount: number;
  facilityManagementBadgeCount: number;

  constructor ( private sidebarService: SidebarService,
                private facilityManagementService: FacilityManagementService,
                private administrationService: AdministrationService ) { }

  ngOnInit() {
    this.sidebarService.setCurrentSidebar('basic');
    this.countAdministrationItems();
    this.countUnreadFacilityManagementTasks();
  }

  countAdministrationItems() {
    this.administrationService.countAllDeleteRequests(false).subscribe((x: number) => {
      this.administrationBadgeCount = x;
    }, err => {
      this.administrationBadgeCount = 0;
      console.log('Error: (countAdministrationItems) ' + err);
    });
  }

  countUnreadFacilityManagementTasks() {
    this.facilityManagementService.countAllUnreadTasks().subscribe((x: number) => {
      this.facilityManagementBadgeCount = x;
    }, err => {
      this.facilityManagementBadgeCount = 0;
      console.log('Error: (countAdministrationItems) ' + err);
    });
  }
}
