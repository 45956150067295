import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../../models/user';
import { PaginatedResult } from 'src/app/models/pagination';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  baseUrl = environment.apiUrl;
  userId = localStorage.getItem('userId');

  private editMode = new BehaviorSubject(false);
  editMode$ = this.editMode.asObservable();

constructor(private http: HttpClient,
            private authService: AuthService) { }

  createUser(model: any) {
    console.log(model);
    model.tenant = this.authService.decodedToken.tenant;
    model.tenantUrl = this.authService.decodedToken.tenantUrl;
    return this.http.post(this.baseUrl + 'user/' + 'createUser', model);
  }

  getUser(id): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'user/' + id);
  }

  getCurrentUser() {
    const userId = localStorage.getItem('userId');
    return this.http.get(this.baseUrl + 'user/current/' + userId);
  }

  updateUser(id: number, idToUpdate: number, user: User) {
    return this.http.put(this.baseUrl + 'user/' + id + '/' + idToUpdate, user);
  }

  updateUserRoles(user: User, roles: string[]) {
    return this.http.post(this.baseUrl + 'user/editRoles/' + user.id, roles); 
  }

  changePassword(userId: number, oldPassword: string, newPassword: string) {
    const model: any = {userId, oldPassword, newPassword };
    return this.http.post(this.baseUrl + 'user/changePassword/', model);
  }

  deleteUser(id: number) {
    return this.http.post(this.baseUrl + 'user/deleteUser/' +  id, {});
  }

  getUsers(page?, itemsPerPage?): Observable<PaginatedResult<User[]>> {
    const paginatedResult: PaginatedResult<User[]> = new PaginatedResult<User[]>();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    return this.http.get<User[]>(this.baseUrl + 'users', {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
        }
        return paginatedResult;
      })
    );
  }

  getUsersWithRoles() {
    return this.http.get(this.baseUrl + 'user/usersWithRoles/' + this.userId)
      .pipe(shareReplay());
  }
}
