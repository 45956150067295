import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

constructor() { }

  private signature: BehaviorSubject<string> = new BehaviorSubject<string>('');
  signature$ = this.signature.asObservable();

  setSignature(signature: string) {
    this.signature.next(signature);
  }

  deleteSignature() {
    this.signature.next('');
  }

  getCurrentSignatureValue(): string {
    return this.signature.value;
  }

}
