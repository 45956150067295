import { Component, OnInit } from '@angular/core';
import {AuthService } from '../../../services/auth/auth.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { Router } from '@angular/router';
import { SelectionListsService } from 'src/app/services/selection-lists/selection-lists.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder,
              public authService: AuthService,
              private slService: SelectionListsService,
              private notificationService: NotificationService) { 

                this.form = this.fb.group({
                  username: ['', Validators.required],
                  password: ['', Validators.required]
                });
              }

  ngOnInit() {
  }

  login() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const data = {
        username: this.form.controls.username.value,
        password: this.form.controls.password.value,

      };
      this.authService.login(data).subscribe(() => {
        this.slService.checkData(); // load data if slDataStore is empty
      },
        error => {
        this.notificationService.error(error);
        this.form.get('password').setValue('');
      });
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.notificationService.message('Abgemeldet');
  }

}
