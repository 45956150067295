import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  loginMode = true;
  values: any;

  constructor(public authService: AuthService) { }

  ngOnInit() {
  }

  loginToggle() {
    this.loginMode = true;
  }

  cancelRegisterMode(loginMode: boolean) {
    this.loginMode = loginMode;
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  getValues() {

  }

}
