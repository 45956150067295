import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { sortKeys } from 'src/app/utils/sort-by-keys/sort-keys.operator';
import { SelectionListItem } from 'src/app/models/selection-list/selectionListItem';

@Injectable({
  providedIn: 'root'
})
export class SelectionListsBackendService {

  baseUrl = environment.apiUrl;
  controller = 'selectionLists/';

constructor(private http: HttpClient) { }

  getAllSelectionListItems(): any {
    return this.http.get(this.baseUrl + this.controller + 'getAllSelectionListItems/')
      .pipe( sortKeys('itemTopic', 'itemValue'), shareReplay());
  }

  createSelectionListItem(item: SelectionListItem): any {
    return this.http.post<SelectionListItem>(this.baseUrl + this.controller + 'saveSelectionListItem/', item)
      .pipe(shareReplay());
  }

  updateSelectionListItem(item: SelectionListItem, oldValue: string): any {
    return this.http.post<SelectionListItem>(this.baseUrl + this.controller + 'updateSelectionListItem/' + oldValue, item)
      .pipe(shareReplay());
  }

  deleteSelectionListItem(item: SelectionListItem): any {
    return this.http.post(this.baseUrl + this.controller + 'deleteSelectionListItem/', item)
      .pipe(shareReplay());
  }
}
