import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormService } from 'src/app/services/form/form.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  siteKey: string;

  constructor( private fb: FormBuilder,
               private formService: FormService,
               private authService: AuthService,
               private notificationService: NotificationService,
               private router: Router) {

                this.siteKey = '6LddBeIZAAAAAApv5wTVNnIHEhQ3ZCxdM8f3ZCyU';

                this.form = this.fb.group({
                  username: ['', Validators.required],
                  recaptcha: ['', Validators.required]
                });
               }

  ngOnInit() {
  }

  resetPassword() {
    this.formService.findInvalidControls(this.form);
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const data = {
        username: this.form.controls.username.value,
      };

      console.log(data);

      this.authService.resetPassword(data).subscribe(() => {
        this.notificationService.success('Passwort wurde zurückgesetzt.');
        this.router.navigate(['/login']);
      },
      err => {
        this.notificationService.error(err);
        console.log('Daten konnten nicht gesendet werden. ' + err);
      });

    } else {
      this.notificationService.error('Bitte füllen Sie alle Felder aus');
    }
  }

}
