import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { ModalService } from 'src/app/modal';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SignatureService } from 'src/app/services/signature/signature.service';
import { Signature } from 'typescript';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements AfterViewInit {

  signatureImg: string;
  @Output() fireFunction = new EventEmitter();
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 3,
    'canvasWidth': 530,
    'canvasHeight': 150,
    'penColor': 'black',
  };

  constructor(
    private signatureService: SignatureService,
    private notificationService: NotificationService,
    private modalService: ModalService
    ) { }
  
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 2); 
    this.signaturePad.clear(); 
  }

  drawComplete() {
    // console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureService.deleteSignature();
  }

  savePad() {
    if (this.signaturePad.isEmpty()) {
      this.notificationService.error('Bitte unterschreiben')
    } else {
      const base64Data = this.signaturePad.toDataURL();
      // this.signatureImg = base64Data;
      this.signatureService.setSignature(base64Data);
      this.signaturePad.clear();
      this.cancelModal('signatureModal');
      this.fireFunction.next();
    }
  }

  cancelModal(id: string) {
    this.signaturePad.clear();
    this.modalService.close(id);
  }
}

