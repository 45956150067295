import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { delay } from 'q';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  selectedColorTheme: string;
  selectedLanguage: string;

  settingsForm: FormGroup;

  constructor(private settingsService: SettingsService,
              private router: Router,
              private sidebarService: SidebarService,
              private fb: FormBuilder,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.sidebarService.setCurrentSidebar('basic');
    this.loadCurrentSettings();
    this.createSettingsForm();
  }

  createSettingsForm() {
    this.settingsForm = this.fb.group({
      language: [this.selectedLanguage],
      colorTheme: [this.selectedColorTheme],
    });
  };

  loadCurrentSettings() {
    if (localStorage.getItem('locale') === 'en') {
      this.selectedLanguage = 'English';
    } else if (localStorage.getItem('locale') === 'de') {
      this.selectedLanguage = 'Deutsch';
    }
    this.selectedColorTheme = localStorage.getItem('appTheme');
  }

  saveSettings() {
    this.setColorTheme();
    this.setLanguage();
  }

  setColorTheme() {
    this.settingsService.changeColorTheme(this.settingsForm.get('colorTheme').value);
    this.notificationService.success('Einstellungen gespeichert');
  }

  setLanguage() {
    const lang: string = this.settingsForm.get('language').value;
    if (lang === 'en') {
      localStorage.setItem('locale', 'en');
    }
    if (lang === 'de') {
      localStorage.setItem('locale', 'de');
    }
  }
};