import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageViewerService } from 'src/app/services/image-viewer/image-viewer.service';

@Component({
  selector: 'tm-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnDestroy {

  imageUrl: string = null;

  //Subscriptions
  imageUrlSubscription: Subscription;
  
  constructor(private imageViewerService: ImageViewerService) { 
  }


  ngOnInit(): void {
    this.imageUrlSubscription = this.imageViewerService.imageUrl$.subscribe(value => {
      this.imageUrl = value;
    });
  }

  ngOnDestroy(): void {
    this.imageUrlSubscription.unsubscribe();
  }

  onSelect() {
   this.imageViewerService.setImageUrl(null)
  }

}
