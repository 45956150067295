import { Component, OnInit } from '@angular/core';
import data from '../../../../../assets/json/mojura-android-versions.json';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-mojura-android',
  templateUrl: './mojura-android.component.html'
})
export class MojuraAndroidComponent implements OnInit {

  data = data;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

}
