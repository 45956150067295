<div id="sidemenu" class="sidebar">
  <div class="sidebar-content-wrapper">
    
    <div id="GasManagementSidebar" *ngIf="currentSidebar == 'gasManagement'">
      <div class="nav-item" (click)="toggleSidebar()">
        <p><span class="icon storage light"></span> Lagerübersicht</p>
      </div>
      <div class="nav-item" (click)="toggleSidebar()">
        <p><span class="icon search-document light"></span> Bestand nach Gas</p>
      </div>
      <div class="nav-item" (click)="toggleSidebar()" routerLink="/gas-booking">
        <p><span class="icon transaction light"></span> Buchungen</p>
      </div>
      <div class="nav-item" (click)="toggleSidebar()" routerLink="/gas-data-management">
        <p><span class="icon edit-data light"></span> Gase-Datenbank</p>
      </div>
      <div class="divider light"></div>
    </div>

    <div id="AdministrationSidebar" *ngIf="currentSidebar == 'administration'">
      <div class="nav-item" (click)="toggleSidebar()" routerLink="/administration/delete-requests-overview">
        <p><span class="icon trash light"></span>Löschaufträge offen</p><span *ngIf="activeDeleteRequestsBadgeCount > 0" class="badge sb">{{activeDeleteRequestsBadgeCount}}</span>
      </div>
      <div class="nav-item" (click)="toggleSidebar()" routerLink="/administration/delete-requests-archive">
        <p><span class="icon archive-trash light"></span>Löschaufträge archiviert</p>
      </div>
      <div class="divider light"></div>
    </div>

    <div id="LimsAnalysesDatabase" *ngIf="currentSidebar == 'lims-analyses'">
      <div class="nav-item">
        <input (change)="showFinishedAnalyseauftraege($event.target.checked)" type="checkbox" class="checkbox" id="showFinishedAnalyseauftraege">
            <label for="showFinishedAnalyseauftraege">Abgeschlossene Analysen</label>
      </div>
      <div class="divider light"></div>
    </div>

    <div id="FacilityManagementSidebar" *ngIf="currentSidebar == 'facility-management'">
      <div styles="padding: 16px" class="nav-item" (click)="toggleSidebar()" routerLink="/facility-management/fm-tasks-overview">
        <input (change)="showFinishedFacilityManagementTasks($event.target.checked)" type="checkbox" class="checkbox" id="showFinishedTasks">
            <label for="showFinishedTasks">Abgeschlossene Aufgaben</label>
      </div>
      <div class="divider light"></div>
    </div>

    <div id="BasicSidebar" class="">
      <div *ngIf="loggedIn()" class="nav-item" (click)="toggleSidebar()" routerLink="/user-profile" routerLinkActive="active">
        <p><span class="icon account light"></span> Profil</p>
      </div>
      <div class="nav-item" (click)="toggleSidebar()" routerLink="/downloads" routerLinkActive="active">
        <p><span class="icon downloads light"></span> Downloads</p>
      </div>
      <div *ngIf="loggedIn()" class="nav-item" (click)="toggleSidebar()" routerLink="/settings" routerLinkActive="active">
        <p><span class="icon settings light"></span> Einstellungen</p>
      </div>
      <div *ngIf="loggedIn()" class="nav-item" (click)="logout()">
        <p><span class="icon logout light"></span> Abmelden</p>
      </div>
      <div *ngIf="!loggedIn()" class="nav-item" (click)="toggleSidebar()" routerLink="/login">
          <p><span class="icon logout light"></span> Anmelden</p>
        </div>
    </div>
  </div> <!-- end sidebar-content-wrapper -->
</div> <!-- end sidebar -->