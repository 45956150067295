import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {

  private imageUrl = new BehaviorSubject<string>(null)
  imageUrl$ = this.imageUrl.asObservable();

constructor() { }
  
  setImageUrl(imageUrl: string) {
    this.imageUrl.next(imageUrl);
  }
}
