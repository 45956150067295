import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user/user.service';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { FormService } from 'src/app/services/form/form.service';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private sidebarService: SidebarService,
              private formService: FormService,
              private userService: UserService,
              private notificationService: NotificationService) {
                this.getUserData();
              }

  user: User;
  currentUser: User;
  userProfileForm: FormGroup;
  changePasswordForm: FormGroup;

  ngOnInit() {
    this.sidebarService.setCurrentSidebar('basic');
    this.createEditUserForm();
    this.userProfileForm.disable();
    this.createChangePasswordForm();
  }

  getUserData() {
    this.userService.getCurrentUser().subscribe((user: User) => {
      this.user = user;
    }, err => {
      this.notificationService.error('Benutzerdaten konnten nicht abgerufen werden');
      console.log('Error: Benutzerdaten konnten nicht abgerufen werden. ' + err);
    });
  }

  changePassword() {
    this.formService.findInvalidControls(this.changePasswordForm);
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
    this.userService.changePassword(
      this.user.id,
      this.changePasswordForm.get('oldPassword').value,
      this.changePasswordForm.get('newPassword').value,
      )
      .subscribe(next => {
        this.notificationService.success('Passwort wurde geändert');
        this.changePasswordForm.reset();
      }, error => {
        this.notificationService.error('Passwort konnte nicht geändert werden');
        this.changePasswordForm.reset();
      });
    } else {
      this.notificationService.error('Bitte prüfen Sie Ihre Angaben');
    }
  }

  createEditUserForm() {
    this.userProfileForm = this.fb.group({
      givenname: ['', Validators.required],
      surname: ['', Validators.required],
      username: ['', Validators.required],
      userRoles: ['', Validators.required]});
  }
  createChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', {validators: [
        Validators.required,
        Validators.minLength(8)
        ],
        updateOn: 'blur'} ],
      repeatNewPassword: ['', {validators: [
        Validators.required,
        Validators.minLength(8)
        ],
        updateOn: 'blur'} ]},
        {validators: [this.passwordMatchValidator, this.passwordStrengthValidator]});
  }

  passwordMatchValidator(g: FormGroup) {
    const password: string = g.get('newPassword').value;
    const confirmPassword: string = g.get('repeatNewPassword').value;
    if (password !== confirmPassword) {
      g.get('repeatNewPassword').setErrors({ NoPasswordMatch: true });
    }
  }

  passwordStrengthValidator(g: FormGroup) {
      const password: string = g.get('newPassword').value;
      const allowedCharacters = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if (!password.match(allowedCharacters)) {
        g.get('newPassword').setErrors({ weakPasswordStength: true });
      }  
  }

}
