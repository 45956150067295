<div id="theme" [ngClass]="this.settingsService.themeClass" class="">
  <tm-image-viewer></tm-image-viewer>
  <div class="base">
    <app-sidebar></app-sidebar>
    
    <div class="app-wrapper">

      <app-navbar></app-navbar>
      <div id="content-wrapper">
        <div class="container" [style.width]="getScreenSize()">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="connectivityToast" [ngClass]="{'visible' : noInternetConnection}" *ngIf="noInternetConnection"
        style="text-align: center;">
        <p>Keine Verbindung</p>
      </div>

      <app-footer></app-footer>
      

    </div>
  </div>
</div>