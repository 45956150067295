import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LabSampleReport } from 'src/app/models/lims/labSampleReport';
import { BehaviorSubject } from 'rxjs';
import { LimsMaterialItem } from 'src/app/models/lims/limsMaterialItem';
import { LabSamplePreparedLabel } from 'src/app/models/lims/labSamplePreparedLabel';
import { LabSample } from 'src/app/models/lims/labSample';
import { stringify } from 'querystring';
import { NotificationService } from '../notification/notification.service';
import { LimsAnalysis } from 'src/app/models/lims/limsAnalysis';

@Injectable({
  providedIn: 'root'
})

export class LimsService {

  baseUrl = environment.apiUrl;
  controller = 'lims/';

  sortValuePreparedLabels = 'created';
  sortDirectionPreparedLabels = 'DESC';

  private editMode = new BehaviorSubject(false);
  editMode$ = this.editMode.asObservable();

  private showFinishedAnalyseauftraege = new BehaviorSubject(false);
  showFinishedAnalyseauftraege$ = this.showFinishedAnalyseauftraege.asObservable();

  private currentPagePreparedLabels = new BehaviorSubject(1);
  currentPagePreparedLabels$ = this.currentPagePreparedLabels.asObservable();

  private preparedLabels = new BehaviorSubject<LabSamplePreparedLabel[]>([])
  preparedLabels$ = this.preparedLabels.asObservable();

  private currentAnalysis = new BehaviorSubject<LimsAnalysis>({
    id: null,
    created: null,
    analyseauftragId: null,
    probeId: null,
    isFinished: null,
    erfassungData: {
      aussteller: null,
      geplantesAbschlussdatum: null,
      projektkennwort: null,
      projektnummer: null,
      analyseGruppe: null,
      analyseName: null,
      analyseBeschreibung: null,
      ergebnisTyp: null,
      probebezeichnung: null,
      probeherkunft: null,
      ghsEinstufung: null,
      bearbeiter: null,
    },
    probevorbereitungData: {
      bearbeiter: null,
      status: null,
      probevorbereitung: null,
      annahmezeitpunkt: null,
      abschlusszeitpunkt: null
    },
    analyseData: {
      bearbeiter: null,
      status: null,
      ergebnis: null,
      annahmezeitpunkt: null,
      abschlusszeitpunkt: null
    },
    prioritaetData: {
      projektprioritaet: 0,
      prioritaetDynamisch: 0,
      prioritaetKurzfristig: 0,
      anzahlVergangenerArbeitstage: 0,
      gesamtzeitraumInArbeitstagen: 0
    },
    type: "LimsAnalysis"
    }
 )
 currentAnalysis$ = this.currentAnalysis.asObservable();

  // LabelData
  private labelData: BehaviorSubject<LabSamplePreparedLabel> = new BehaviorSubject<LabSamplePreparedLabel>({
    id: null, created: null, ersteller: null, anzahlDerEtiketten: null, jahr: null, artDerProbe: null, projektnummer: null, projektkennwort: null, 
    beschreibung: null, notizen: null, frcGhsCode: null, gefStoffSammelanweisung: null, frcGhsSymbol1: null, frcGhsSymbol2: null, frcGhsSymbol3: null, frcGhsSymbol4: null, 
    material: null, materialKurzbezeichnung: null, behandlung: null, lw515: null, herkunft: null, probenahmestelle: null, datum: null, zeitraumBeginn: null, 
    zeitraumEnde: null, zeitpunkt: null, ohneZeitangaben: null, probeaufbereitungStatus: null, probeaufbereitungTrocknung: null,  probeaufbereitungKlassierung: null, 
    probeaufbereitungKlassierungEinheit: null, probeaufbereitungKlassierungWert1: null, probeaufbereitungKlassierungWert2: null, probeaufbereitungZerkleinerung: null, 
    probeaufbereitungAnalytikFeinheit: null, probeaufbereitungZusammenfassung: null, materialnummer: null, printed: null, type: null
  });
  labelData$ = this.labelData.asObservable();

  // MaterialItem
  private materialItem: BehaviorSubject<LimsMaterialItem> = new BehaviorSubject<LimsMaterialItem>({
    id: null, created: null, probeId: null, materialnummer: null, datum: null, verpackung: null, gewicht: null, gewichtseinheit: null,
    restmenge: null, material: null, absender: null, gefahrstoff: null, konto: null, frcGhsCode: null, strahlung: null, zustMitarbeiter: null,
    materialentnahmen: null, type: null
  })
  materialItem$ = this.materialItem.asObservable();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
    ) { }

  loadItemsFromServer() {
    console.log("Load ITEMS")
    const offset = Math.floor((1 * 100) - 100).toString();
    this.getPreparedLabSampleLabels("100", offset, this.sortValuePreparedLabels, this.sortDirectionPreparedLabels, false).toPromise()
    .then((items: LabSamplePreparedLabel[]) => {
      this.setPreparedLabels(items)
      console.log(items)
    })
    .catch(error => {
      console.log(error)
      this.notificationService.error('Objekte konnten nicht abgerufen werden');
    });
  }

  setShowFinishedAnalyseauftraege(value: boolean) {
    console.log(value)
    this.showFinishedAnalyseauftraege.next(value);
  }

  setEditMode(editMode: boolean) {
    this.editMode.next(editMode);
  }

  setPreparedLabels(data: any[]) {
    this.preparedLabels.next(data);
  }

  setCurrentAnalysis(data: LimsAnalysis) {
    console.log(data)
    this.currentAnalysis.next(data);
  }

  setCurrentPagePreparedLabels(page: number) {
    this.currentPagePreparedLabels.next(page)
  }

  setLabelData(data: LabSamplePreparedLabel) {
    this.labelData.next(data);
  }

  setLabelDataFromLabSample(data: any) {
    let item: LabSamplePreparedLabel = {
      id: null,
      created: null,
      ersteller: null,
      anzahlDerEtiketten: null,
      jahr: data.jahr,
      artDerProbe: data.artDerProbe,
      projektnummer: data.projektnummer,
      projektkennwort: data.projektkennwort,
      beschreibung: data.beschreibung,
      notizen: data.notizen,
      frcGhsCode: data.frcGhsCode,
      gefStoffSammelanweisung: data.gefStoffSammelanweisung,
      frcGhsSymbol1: data.frcGhsSymbol1,
      frcGhsSymbol2: data.frcGhsSymbol2,
      frcGhsSymbol3: data.frcGhsSymbol3,
      frcGhsSymbol4: data.frcGhsSymbol4,
      material: data.material,
      materialKurzbezeichnung: data.materialKurzbezeichnung,
      behandlung: data.behandlung,
      lw515: data.lw515,
      herkunft: data.herkunft,
      probenahmestelle: data.probenahmestelle,
      datum: data.datum,
      zeitraumBeginn: data.zeitraumBeginn,
      zeitraumEnde: data.zeitraumEnde,
      zeitpunkt: data.zeitpunkt,
      ohneZeitangaben: data.ohneZeitangaben,
      probeaufbereitungStatus: data.probeaufbereitungStatus,
      probeaufbereitungTrocknung: data.probeaufbereitungTrocknung,
      probeaufbereitungKlassierung: data.probeaufbereitungKlassierung,
      probeaufbereitungKlassierungEinheit: data.probeaufbereitungKlassierungEinheit,
      probeaufbereitungKlassierungWert1: data.probeaufbereitungKlassierungWert1,
      probeaufbereitungKlassierungWert2: data.probeaufbereitungKlassierungWert2,
      probeaufbereitungZerkleinerung: data.probeaufbereitungZerkleinerung,
      probeaufbereitungAnalytikFeinheit: data.probeaufbereitungAnalytikFeinheit,
      probeaufbereitungZusammenfassung: null,
      materialnummer: data.materialnummer,
      printed: null,
      type: "LimsPreparedLabel"
    }
    this.labelData.next(item);
    console.log('DATA')
    console.log(data)
  }

  setMaterialItem(data: LimsMaterialItem) {
    this.materialItem.next(data);
  }


  getLimsAnalysisSearchResult(searchTopic: string, searchValue: string, isFinished: boolean) {
    return this.http.get(this.baseUrl + this.controller + 'getLimsAnalysisSearchResult/' + searchTopic + '/' + searchValue + '/' + isFinished);
  }

  countItems(type: string) {
    return this.http.get(this.baseUrl + this.controller + 'countItems/' + type);
  }

  getNewAnalyseauftragId() {
    return this.http.get(this.baseUrl + this.controller + 'getNewAnalyseauftragId/');
  }

  calculatePriorities() {
    return this.http.get(this.baseUrl + this.controller + 'calculatePriorities/');
  }

  saveAnalysenauftrag(analyse: LimsAnalysis) {
    return this.http.post(this.baseUrl + this.controller + 'saveAnalyse/', analyse);
  }

  saveAnalysenauftraege(analysen: LimsAnalysis[]) {
    return this.http.post(this.baseUrl + this.controller + 'saveAnalyseauftraege/', analysen);
  }

  getAnalyseauftraege(limit: string, offset: string, orderBy: string = 'id', orderDirection: string = 'desc', isFinished: boolean = false) {
    return this.http.get(this.baseUrl + this.controller + 'getAnalyseauftraege/' + limit + '/' + offset + '/' + orderBy + '/' + orderDirection + '/' + isFinished);
  }

  getAllMaterialItems() {
    return this.http.get(this.baseUrl + this.controller + 'getAllMaterialItems/');
  }

  getMaterialItems(limit: string, offset: string, orderBy: string = 'probeId', orderDirection: string = 'desc') {
    return this.http.get(this.baseUrl + this.controller + 'getMaterialItems/' + limit + '/' + offset + '/' + orderBy + '/' + orderDirection);
  }

  getMaterialItemsAdvancedSearch(criteria) {
    return this.http.put(this.baseUrl + this.controller + 'getMaterialItemsByCriteria/', criteria);
  }

  getMaterialItemsSearchResult(searchTopic: string, searchValue: string) {
    return this.http.get(this.baseUrl + this.controller + 'getMaterialItemsSearchResult/' + searchTopic + '/' + searchValue);
  }

  getAllLabSamples() {
    return this.http.get(this.baseUrl + this.controller + 'getAllLabSamples/');
  }

  getPreparedLabSampleLabels(limit: string, offset: string, orderBy: string = 'created', orderDirection: string = 'asc', printed: boolean = false) {
    return this.http.get(this.baseUrl + this.controller + 'getPreparedLabSampleLabels/' + limit + '/' + offset + '/' + orderBy + '/' + orderDirection + '/' + printed);
  }

  getLabSamples(limit: string, offset: string, orderBy: string = 'probeId', orderDirection: string = 'desc') {
    return this.http.get(this.baseUrl + this.controller + 'getLabSamples/' + limit + '/' + offset + '/' + orderBy + '/' + orderDirection);
  }

  getLabSample(probeId: string) {
    return this.http.get(this.baseUrl + this.controller + 'getLabSample/' + probeId);
  }

  getLabSamplesAdvancedSearch(criteria) {
    return this.http.put(this.baseUrl + this.controller + 'getLabSamplesByCriteria/', criteria);
  }

  getLabSamplesSearchResult(searchTopic: string, searchValue: string) {
    return this.http.get(this.baseUrl + this.controller + 'getLabSamplesSearchResult/' + searchTopic + '/' + searchValue);
  }

  getAllLabSamplesOrderBy(orderBy: string, order: string) {
    return this.http.get(this.baseUrl + this.controller + 'getAllLabSamplesOrderBy/' + orderBy + '/' + order);
  }

  getLabSampleReportsByProbeId(probeId: string) {
    return this.http.get(this.baseUrl + this.controller + 'getLabSampleReportsByProbeId/' + probeId);
  }

  saveLabSampleReport(report: LabSampleReport) {
    return this.http.post(this.baseUrl + this.controller + 'saveLabSampleReport/', report);
  }

  deleteLabSampleReports(reports: string[]) {
    return this.http.post(this.baseUrl + this.controller + 'deleteLabSampleReports/', reports);
  }

  getAllLimsDataItemsFromType(type: string) {
    return this.http.get(this.baseUrl + this.controller + 'getAllLimsDataItemsFromType/' + type);
  }

  saveItem(item: any) {
    return this.http.post(this.baseUrl + this.controller + 'saveItem/', item);
  }
  
  saveChanges(item: any) {
    return this.http.post(this.baseUrl + this.controller + 'saveChanges/', item);
  }

  saveNewSelectionListItem(item: any) {
    return this.http.post(this.baseUrl + this.controller + 'saveNewSelectionListItem/', item);
  }

  deleteItem(id: string) {
    console.log(id)
    return this.http.delete(this.baseUrl + this.controller + 'deleteItem/' + id);
  }

  createEmptyLabel() {
    const item: any = {
      id: null,
      created: null,
      ersteller: null,
      anzahlDerEtiketten: null,
      jahr: new Date().getFullYear().toString(),
      artDerProbe: null,
      projektnummer: null,
      projektkennwort: null,
      beschreibung: null,
      notizen: null,
      frcGhsCode: null,
      gefStoffSammelanweisung: null,
      frcGhsSymbol1: null,
      frcGhsSymbol2: null,
      frcGhsSymbol3: null,
      frcGhsSymbol4: null,
      material: null,
      materialKurzbezeichnung: null,
      behandlung: null,
      lw515: null,
      herkunft: null,
      probenahmestelle: null,
      datum: null,
      zeitraumBeginn: null,
      zeitraumEnde: null,
      zeitpunkt: null,
      ohneZeitangaben: null,
      probeaufbereitungStatus: null,
      probeaufbereitungTrocknung: null,
      probeaufbereitungKlassierung: null,
      probeaufbereitungKlassierungEinheit: null,
      probeaufbereitungKlassierungWert1: null,
      probeaufbereitungKlassierungWert2: null,
      probeaufbereitungZerkleinerung: null,
      probeaufbereitungAnalytikFeinheit: null,
      probeaufbereitungZusammenfassung: null,
      materialnummer: null,
      printed: false,
      type: 'LabSampleLabelFromWeb'
    }
    return item;
  }
}
