import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalUseModule } from './global-use.module';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { MessagesModule } from './components/core/messages/messages.module';
import { ModalModule } from './modal';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/core/dashboard/dashboard.component';
import { FooterComponent } from './components/core/navigation/footer/footer.component';
import { HomeComponent } from './components/core/home/home.component';
import { LoginComponent } from './components/core/login/login.component';
import { NavbarComponent } from './components/core/navigation/navbar/navbar.component';
import { PaginationComponent } from './components/core/pagination/pagination.component';
import { SettingsComponent } from './components/core/settings/settings.component';
import { SidebarComponent } from './components/core/navigation/sidebar/sidebar.component';
import { UserProfileComponent } from './components/core/user-profile/user-profile.component';
import { MessagesResolver } from './resolvers/messages.resolver';
import { UserListResolver } from './resolvers/user-list.resolver';
import { ErrorInterceptorProvider } from './services/error.interceptor';
import { DownloadsComponent } from './components/core/downloads/downloads.component';
import { AccordionComponent } from './components/core/accordion/accordion.component';
import { AccordionContentComponent } from './components/core/accordion/accordion-content/accordion-content.component';
import { AccordionGroupComponent } from './components/core/accordion/accordion-group/accordion-group.component';
import { AccordionHeaderComponent } from './components/core/accordion/accordion-header/accordion-header.component';
import { AccordionContentLazyDirective } from './components/core/accordion/accordion-content-lazy.directive';
import { MojuraAndroidComponent } from './components/core/downloads/mojura-android/mojura-android.component';
import { ResetPasswordComponent } from './components/core/reset-password/reset-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDE from "@angular/common/locales/de";
registerLocaleData(localeDE, "de");

export function tokenGetter() {
  return localStorage.getItem('token');
}

const COMPONENTS = [
   AccordionComponent,
   AccordionContentComponent,
   AccordionGroupComponent,
   AccordionHeaderComponent,
   AppComponent,
   DashboardComponent,
   FooterComponent,
   HomeComponent,
   LoginComponent,
   NavbarComponent,
   SidebarComponent,
   ResetPasswordComponent,
   UserProfileComponent,
   SettingsComponent,
   PaginationComponent,
   MojuraAndroidComponent,
   DownloadsComponent,
];

const DIRECTIVES = [
   AccordionContentLazyDirective,
];

const MODULES = [
   NgxCaptchaModule,
   GlobalUseModule,
   BrowserAnimationsModule,
   ModalModule,
   HttpClientModule,
   FormsModule,
   ReactiveFormsModule,
   MessagesModule,
   JwtModule.forRoot({
      config: {
         tokenGetter: tokenGetter,
         whitelistedDomains: ['localhost:5011'],
         blacklistedRoutes: ['localhost:5011/api/auth']
      }
   }),
];

const PIPES = [];

const RESOLVERS = [
   UserListResolver,
   MessagesResolver,
];

const SERVICES = [
    ErrorInterceptorProvider,
    AuthGuard
];

@NgModule({
   declarations: [
      ...COMPONENTS,
      ...DIRECTIVES,
      ...PIPES,
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      ServiceWorkerModule.register('ngsw-worker.js', { 
         enabled: environment.production 
      }),
      ...MODULES,
      NoopAnimationsModule,
      
  ],
  providers: [
     ...SERVICES,
     ...RESOLVERS
    ],
  bootstrap: [AppComponent]
})

export class AppModule { }
