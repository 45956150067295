import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, refCount, shareReplay } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { SidebarService } from '../sidebar/sidebar.service';
import { NotificationService } from '../notification/notification.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthService {

  baseUrl = environment.apiUrl;
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  tenant: string;
  tenantUrl: string;
  username: string;

  private readonly roleAdmin: string[] = ['Admin'];
  private readonly roleUser: string[] = ['User'];

constructor(private http: HttpClient,
            private sidebarService: SidebarService,
            private notificationService: NotificationService,
            private router: Router) { }

login(model: any) {
  return this.http.post(this.baseUrl + 'auth/' + 'login', model)
    .pipe(
      map((response: any) => {
        const user = response;
        console.log(user);
        if (user) {
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          this.username = this.decodedToken.username;
          if (this.decodedToken.role[0] === 'Disabled') {
            this.notificationService.error('Das Benutzerkonto ist deaktiviert!');
          } else {
            localStorage.setItem('token', user.token);
            localStorage.setItem('username', user.user.username);
            localStorage.setItem('userId', user.user.id);
            this.router.navigate(['/home']);
            this.notificationService.success('Anmeldung erfolgreich');
          }
        }
      }),
      shareReplay()
    );
  }

  resetPassword(model: any) {
    return this.http.post(this.baseUrl + 'auth/' + 'resetPassword/', model).pipe(shareReplay(), refCount());
  }

  getUsernameFromToken() {
    const decoded = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    const userFromToken = decoded.unique_name;
    return userFromToken;
  }

  getUserRolesFromToken() {
    const decoded = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    const userRolesFromToken = decoded.role as Array<string>;
    return userRolesFromToken;
  }

  userHasRole(role: string) : boolean {
    const decoded = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    const userRolesFromToken = decoded.role as Array<string>;
    if(userRolesFromToken.includes(role)) { return true } else { return false};
  }

  getUsersFullNameFromToken() {
    const decoded = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    const fullName = decoded.name;
    return fullName;
  }

  isEqualToUsernameFromToken(username: string): boolean {
    const decoded = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    const userFromToken = decoded.unique_name;
    if (username === userFromToken) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('givenName');
    localStorage.removeItem('surname');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    this.sidebarService.hideSidebar();
    this.router.navigate(['/home']);
    this.notificationService.message('Abgemeldet');
  }

  logSession() {
    const user = localStorage.getItem('userId');
    return this.http.get(this.baseUrl + 'auth/' + 'logSession/' + user)
    .pipe(shareReplay());
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const userRoles = this.decodedToken.role as Array<string>;
    allowedRoles.forEach(element => {
      if (userRoles.includes(element)) {
        isMatch = true;
      }
    });
    return isMatch;
  }

  moduleMatchFromRoles(module): boolean {
    let isMatch = false;
    const userRoles = this.decodedToken.role as string[];
    for (var i = 0, len = userRoles.length; i < len; i++) {
      if (userRoles[i].includes(module) && !userRoles[i].includes('Disabled')) {
        isMatch = true;
      }
    }
    return isMatch;
  }

  isAdmin() {
    if (this.roleMatch(this.roleAdmin)) {
      return true;
    } else {
      return false;
    }
  }

  isAdminOrResposiblePerson(username: string) {
    if (this.isAdmin() || this.isEqualToUsernameFromToken(username)) {
      return true;
    } else {
      return false;
    }
  }

  isUser() {
    if (this.roleMatch(this.roleUser)) {
      return true;
    } else {
      return false;
    }
  }

}
