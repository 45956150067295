import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeleteRequestItem } from 'src/app/models/administration/delete-request-item';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  baseUrl = environment.apiUrl;
  controller = 'administration/';

  constructor(private http: HttpClient) { }

  createDeleteRequest(deleteRequest: DeleteRequestItem) {
    return this.http.put(this.baseUrl + this.controller + 'createDeleteRequest/', deleteRequest);
  }

  getAllActiveDeleteRequests() {
    return this.http.get(this.baseUrl + this.controller + 'getAllActiveDeleteRequests/');
  }

  countAllDeleteRequests(isClosed: boolean) {
    return this.http.get(this.baseUrl + this.controller + 'countAllDeleteRequests/' + isClosed);
  }

  getAllArchivedDeleteRequests() {
    return this.http.get(this.baseUrl + this.controller + 'getAllArchivedDeleteRequests/');
  }

  getArchivedDeleteRequests(limit: string, offset: string, orderBy: string = 'created', orderDirection: string = 'asc') {
    return this.http.get(this.baseUrl + this.controller + 'getArchivedDeleteRequests/' + limit + '/' + offset + '/' + orderBy + '/' + orderDirection);
  }

  deleteItems(items: string[]) {
    const senderId = localStorage.getItem('userId');
    return this.http.post(this.baseUrl + this.controller + 'deleteItems/' + senderId.toString(), items);
  }

  cancelDeleteRequests(items: string[]) {
    return this.http.post(this.baseUrl + this.controller + 'cancelDeleteRequests/', items);
  }
}
