import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FacilityManagementTask } from 'src/app/models/facility-management/facilityManagementTask';
import { environment } from 'src/environments/environment';
import tasksJson from '../../../assets/json/facility-management-tasks.json'
import { TableService } from '../table/table.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityManagementService {

  baseUrl = environment.apiUrl;
  controller = 'FacilityManagement/';
  facilityManagementTasks: FacilityManagementTask[];

  private editMode = new BehaviorSubject(false);
  editMode$ = this.editMode.asObservable();

  private showFinishedTasks = new BehaviorSubject(false);
  showFinishedTasks$ = this.showFinishedTasks.asObservable();

  private facilityManagementTask: BehaviorSubject<FacilityManagementTask> = new BehaviorSubject<FacilityManagementTask>({
    id: null,
    created: null,
    date: null,
    reportedBy: null,
    finished: null,
    isFinished: false,
    unread: true,
    building: null,
    buildingAddition: null,
    topic: null,
    customTopic: null,
    description: null,
    responsibility: null,
    safetyRelevant: null,
    riskObservation: null,
    statusList: null,
    notes: null,
    type: 'FmTask'
  });

  facilityManagementTask$ = this.facilityManagementTask.asObservable();

  constructor(private http: HttpClient,
              private tableService: TableService) { }

  setEditMode(editMode: boolean) {
    this.editMode.next(editMode);
  }


  showNewFmTaskComponent() {
    this.tableService.reduceTableSize12('table-col', 'details-col');
    //this.tableExpanded = false;
    //this.itemDetailsMode = false;
    //this.newItemMode = true;
  }

  setShowFinishedTasks(value: boolean) {
    console.log(value)
    this.showFinishedTasks.next(value);
    console.log('finishedTasks : ' + this.showFinishedTasks)
  }
  
  setFmTask(data: FacilityManagementTask) {
    this.facilityManagementTask.next(data);
    console.log('FacilityManagementTask');
    console.log(data);
  }

  getAllFmTasks(isFinished: boolean) {
    return this.facilityManagementTasks = tasksJson;
  }
  
  getFmTasks(limit: string, offset: string, orderBy: string = 'created', orderDirection: string = 'desc', isFinished: boolean = false) {
    return this.http.get(this.baseUrl + this.controller + 'getFmTasks/' + limit + '/' + offset + '/' + orderBy + '/' + orderDirection  + '/' + isFinished);
  }

  countFmTasks(isFinished: boolean) {
    return this.http.get(this.baseUrl + this.controller + 'countFmTasks/' + isFinished);
  }

  saveFmTask(data: any) {
    return this.http.post(this.baseUrl + this.controller + 'saveFmTask/', data);
  }

  deleteFmTask(id: string) {
    return this.http.delete(this.baseUrl + this.controller + 'deleteFmTask/' + id);
  }

  finishFmTask(id: string) {
    return this.http.get(this.baseUrl + this.controller + 'finishFmTask/' + id);
  }

  getFmTaskImages(id: string) {
    return this.http.get(this.baseUrl + this.controller + 'getFmTaskImages/' + id);
  }

  countAllUnreadTasks() {
    return this.http.get(this.baseUrl + this.controller + 'countAllUnreadTasks/');
  }

  markFmTaskAsRead(id: string) {
    return this.http.get(this.baseUrl + this.controller + 'markFmTaskAsRead/' + id);
  }
}
