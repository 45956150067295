import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  constructor(public authService: AuthService,
              public notificationService: NotificationService,
              private sidebarService: SidebarService) { }

  ngOnInit() {
    const requestFullscreen = function(ele) {

      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
      } else {
        
        console.log('Error: Fullscreen API is not supported.');
      }
    };
    const fsDocButton = document.getElementById('fullscreenBtn');

    fsDocButton.addEventListener('click', function(e) {
      e.preventDefault();
      requestFullscreen(document.documentElement);
    });
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

}
