import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private currentTab = new BehaviorSubject('Allgemein');
  currentTab$ = this.currentTab.asObservable();

constructor() {}

setCurrentTab(currentTab: string) {
  this.currentTab.next(currentTab);
}

}
