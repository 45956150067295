import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { TabsService } from 'src/app/services/tabs/tabs.service';

@Component({
  selector: 'tm-tabs',
  templateUrl: './tabs.component.html'
})

export class TabsComponent {

  tabs: TabComponent[] = [];
 @Output() selected = new EventEmitter();

 constructor(private tabsService: TabsService) {}

  addTab(tab: TabComponent) {
    if (!this.tabs.length) {
      tab.selected = true;
    }
    this.tabs.push(tab);
  }

  selectTab(tab: TabComponent) {
    this.tabsService.setCurrentTab(tab.tabTitle);
    this.tabs.map((tab) => {
      tab.selected = false;
    });
    tab.selected = true;

    this.selected.emit({selectedTab: tab});
  }
}
