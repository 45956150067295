<div class="app-modal-wrapper">
    <div class="app-modal">
            <div class="toolbar">
                    <div class="title">
                      <h3>
                            {{title}}
                          
                      </h3>
                    </div>  
                 
                      
                  </div> 
        <ng-content></ng-content>
    </div>
</div>
<div class="app-modal-background"></div>