<div class="row">
  <div class="col-md-6 col-sm-12 col-xs-12" style="padding-bottom: 30px;">
    <div class="card">
      <div class="toolbar">
        <div class="title">
          <h3>Mojura Android</h3>
        </div>  
        <div class="btn-bar"></div>
      </div>   <!-- end toolbar -->
      <div class="content p-16">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="presentation-item blue-gradient" >
              <div class="icon-big android"></div>
            </div>
          </div>   <!-- end col -->
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="p-16">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6">
                  <h3>App-Version</h3>
                  <p>{{data[0].version}}</p>
                </div>
                <div class="col-md-6 col-md-6 col-xs-6">
                  <h3>Veröffentlicht</h3>
                  <p>{{data[0].releaseDate}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6">
                  <h3>Min. Android Version</h3>
                  <p>{{data[0].minimumAndroidVersion}}</p>
                </div>
                <div class="col-md-6 col-md-6 col-xs-6">
                  <h3>Target Andoid Version</h3>
                  <p>{{data[0].targetAndroidVersion}}</p>
                </div>
              </div>
              <h3>Beschreibung</h3>
              <p style="text-align: justify">Mojura Andoid ist eine modular erweiterbare Andoid-App, welche eigenständige Module, sowie ergänzende mobile Funktionen zu bestehenden Mojura-Web-Modulen beinhaltet. Mojura Android unterstützt derzeit alle Android Versionen von Version {{data[0].minimumAndroidVersion}} bis {{data[0].targetAndroidVersion}}. Die Installation kann derzeit nur über den hier zur Verfügung stehenden Download-Button durchgeführt werden.</p>
            </div>
          </div>
        </div>  <!-- end row -->
        <a class="btn btn-secondary full btn-download" style="height: auto" href="https://downloads.mojura.de/com.timmuennig.mojura.apk">Download Mojura Android {{data[0].version}}</a>
      </div>   <!-- end content -->
    </div>   <!-- end card -->
  </div>   <!-- end col -->

  <div class="col-md-6 col-sm-12 col-xs-12" style="padding-bottom: 30px;">
    <div  class="card cutted">
      <div class="toolbar">
        <h3>Aktuelle Änderungen in Version {{data[0].version}}</h3>
        <div class="toolbar-buttons"></div>
      </div>   <!-- end toolbar -->
      <div class="content p-16">
        <div *ngIf="loggedIn()">
          <ul  style="margin:0 0 16px 0">
            <li style="padding-bottom: .6em" *ngFor="let value of data[0].changes">{{value}}</li>
          </ul>
        </div>
        <div *ngIf="!loggedIn()">
          <p style="text-align: center; padding-bottom: 15px;">Bitte melden Sie sich an um diesen Inhalt sehen zu können.</p>
        </div>
      </div>   <!-- end content -->
    </div> <!-- end card -->
    <div class="p-15"></div>
    <div class="card cutted">
      <div class="toolbar">
        <h3>Änderungen in Version {{data[1].version}}</h3>
        <div class="toolbar-buttons"></div>
      </div>   <!-- end toolbar -->
      <div class="content p-16">
        <div *ngIf="loggedIn()">
          <ul style="margin:0 0 16px 0">
            <li style="padding-bottom: .6em" *ngFor="let value of data[1].changes">{{value}}</li>
          </ul>
        </div>
        <div *ngIf="!loggedIn()">
          <p style="text-align: center; padding-bottom: 15px;">Bitte melden Sie sich an um diesen Inhalt sehen zu können.</p>
        </div>
      </div>   <!-- end content -->
    </div>   <!-- end card -->
  </div>   <!-- end col -->
</div> <!-- end row -->

<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12" style="padding-bottom: 30px;">
    <div class="card">
      <div class="toolbar">
        <div class="title">
          <h3>Versionshistorie</h3>
        </div>  
        <div class="btn-bar"></div>
      </div>   <!-- end toolbar -->
      <div class="content">
        <div *ngIf="loggedIn()">
          <accordion class="accordion">
            <accordion-group *ngFor="let section of data" class="accordion-card-group">
              <accordion-header class="accordion-card-header" #header="accordionHeader">
                {{ section.module }} - {{ section.version }} - {{ section.releaseDate }}
              </accordion-header>
              <accordion-content>
                <ng-template accordionContentLazy>
                  <div class="accordion-card-body">
                    <ul>
                      <li style="padding-bottom: .6em" *ngFor="let value of section.changes">{{value}}</li>
                    </ul>
                  </div>
                </ng-template>
              </accordion-content>
            </accordion-group>
          </accordion>
        </div>
        <div *ngIf="!loggedIn()">
          <p style="text-align: center; padding-bottom: 15px;">Bitte melden Sie sich an um diesen Inhalt sehen zu können.</p>
        </div>
      </div>   <!-- end content -->
    </div>   <!-- end card -->
  </div>   <!-- end col -->
</div> <!-- end row -->
