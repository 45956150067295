import { Component, OnInit, HostListener, AfterViewInit, ApplicationRef } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SettingsService } from './services/settings/settings.service';
import { SelectionListsService } from './services/selection-lists/selection-lists.service';
import { ConnectionService } from './services/connection/connection.service';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from './services/notification/notification.service';
import { UpdateService } from './services/update/update.service';
import { interval } from 'rxjs';
import { ModalService } from './modal';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  jwtHelper = new JwtHelperService();
  title = 'Mojura';
  scrWidth: any;
  isConnected = true;
  noInternetConnection: boolean;

  constructor(private authService: AuthService,
    public settingsService: SettingsService,
    private slService: SelectionListsService,
    private updateService: UpdateService,
    private connectionService: ConnectionService) {
    this.getScreenSize();
    this.connectionService.connectionMonitor$().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.noInternetConnection = false;
      } else {
        this.noInternetConnection = true;
      }
    });
  }

  ngOnInit(): void {
    this.updateService.checkForUpdate();

    const token = localStorage.getItem('token');
    if (token) {
      this.authService.decodedToken = this.jwtHelper.decodeToken(token);
      this.authService.logSession().subscribe();
    }

    this.settingsService.initializeColorTheme();
    if (this.loggedIn()) {
      this.slService.checkData(); // load data if slDataStore is empty
    }
  }

  ngAfterViewInit(): void { }

  refreshPage() {
    window.location.reload();
  }

  loggedIn(): boolean {
    return this.authService.loggedIn();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    this.scrWidth = this.scrWidth + 'px';
    return this.scrWidth;
  }
}


