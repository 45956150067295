import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/core/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { SettingsComponent } from './components/core/settings/settings.component';
import { MessagesComponent } from './components/core/messages/messages.component';
import { MessagesResolver } from './resolvers/messages.resolver';
import { ConversationComponent } from './components/core/messages/conversation/conversation.component';
import { UserProfileComponent } from './components/core/user-profile/user-profile.component';
import { DownloadsComponent } from './components/core/downloads/downloads.component';
import { LoginComponent } from './components/core/login/login.component';
import { MojuraAndroidComponent } from './components/core/downloads/mojura-android/mojura-android.component';
import { ResetPasswordComponent } from './components/core/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'downloads', component: DownloadsComponent },
  { path: 'mojura-android', component: MojuraAndroidComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'user-profile', component: UserProfileComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'messages', component: MessagesComponent, resolve: {messages: MessagesResolver}},
      { path: 'conversation', component: ConversationComponent },
      {
        path: 'administration',
        loadChildren: () => import('./../app/components/administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'lims',
        loadChildren: () => import('./../app/components/lims/lims.module').then(m => m.LimsModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('./../app/components/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'inspections',
        loadChildren: () => import('./../app/components/inspections/inspections.module').then(m => m.InspectionsModule)
      },
      {
        path: 'facility-management',
        loadChildren: () => import('./../app/components/facility-management/facility-management.module').then(m => m.FacilityManagementModule)
      },
      {
        path: 'gas-management',
        loadChildren: () => import('./../app/components/gas-management/gas-management.module').then(m => m.GasManagementModule)
      },
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
