<div class="card">
  <div class="card-body">
    <div *ngIf="messages?.length === 0">
      No messages yet... say hi by using the message box below
    </div>

    <ul class="chat">
      <li *ngFor="let message of messages">
        <!-- to them -->
        <div *ngIf="message.senderId == recipientId">
          <span class="chat-img float-left">
            <!--<img src="{{message.senderPhotoUrl}}" alt="{{message.senderFullName}}" class="rounded-circle">-->
          </span>
          <div class="chat-body">
            <div class="header">
              <strong class="primary-font">{{message.senderFullName}}</strong>
              <small class="text-muted float-right">
                <span class="fa fa-clock-o">{{message.messageSent | date: 'dd.MM.yyyy - HH:mm'}} Uhr</span>
              </small>
            </div>
            <p>{{message.content}}</p>
          </div>
        </div>

        <!-- to me -->
        <div *ngIf="message.senderId != recipientId">
            <span class="chat-img float-right">
              <!-- <img src="{{message.senderPhotoUrl}}" alt="{{message.senderFullName}}" class="rounded-circle">-->
            </span>
            <div class="chat-body">
              <div class="header">
                  <small class="text-muted">
                      <span class="fa fa-clock-o">{{message.messageSent | date: 'dd.MM.yyyy - HH:mm'}} Uhr</span>
                      <!--<span *ngIf="!message.isRead" class="text-danger">(unread)</span>
                      <span *ngIf="message.isRead" class="text-success">(Read {{message.dateRead}})</span>-->
                    </small>
                <strong class="primary-font float-right">{{message.senderFullName}}</strong>
              </div>
              <p>{{message.content}}</p>
            </div>
          </div>
      </li>
    </ul>

  </div>

  <div class="card-footer">
    <form #messageForm="ngForm" (ngSubmit)="messageForm.valid && sendMessage()">
      <div class="input-group">
        <input type="text"
          [(ngModel)]="newMessage.content"
          name="content"
          required
          class="form-control input-sm"
          placeholder="send a private message">
        <div class="input-group-append">
          <button [disabled]="!messageForm.valid" class="btn btn-primary">Send</button>
        </div>
      </div>
    </form>
  </div>
</div>
