
<p *ngIf="showProgress"> progress <strong>{{percentage}}%</strong></p>


<table class="table tab-table">
    <thead>
      <tr>
        <th style="width: 40px"></th>
        <th>Bezeichnung</th>
        <th style="width: 100px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of files">
          <td style="width: 40px"><input type="checkbox" class="checkbox" id="{{file}}"> <label for="{{file}}"></label></td>
          <td>{{file}}</td>
          <td style="width: 100px"><app-download [disabled]="showProgress" [fileName]="file" (downloadStatus)="downloadStatus($event)"></app-download></td>
      </tr>
    </tbody>
  </table>
  <app-upload [disabled]="showProgress" (uploadStatus)="uploadStatus($event)"></app-upload>