import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { SelectionListItem } from 'src/app/models/selection-list/selectionListItem';
import { NotificationService } from '../notification/notification.service';
import { SelectionListsBackendService } from './selection-lists-backend.service';
import { SelectionListsLocalDataStoreService } from './selection-lists-local-data-store.service';

@Injectable({
  providedIn: 'root'
})
export class SelectionListsService {

readonly selectionListItems: any;

constructor(private backendService: SelectionListsBackendService,
            private dataStoreService: SelectionListsLocalDataStoreService,
            private notificationService: NotificationService) {

             this.selectionListItems = this.dataStoreService.$selectionListItemsObs;
             }

  checkData(): void {
    if ( this.dataStoreService.dataStoreIsEmpty() ) {
      this.loadAll();
    }
  }

  loadAll(): Observable<any> {
    const obs = this.backendService.getAllSelectionListItems().pipe(shareReplay());
    obs.subscribe(
      (data: SelectionListItem[])  => {
        this.dataStoreService.fillDataStore(data);
        this.dataStoreService.refreshDataStore();
      },
      err => {
        this.notificationService.error('Daten konnten nicht geladen werden');
        console.log('Error: Could not load selection list items! ' + err);
      }
    );
    return obs;
  }

  createItem(item: SelectionListItem): Observable<any> {
    const obs = this.backendService.createSelectionListItem(item);
    obs.subscribe(
      (res: SelectionListItem) => {
        this.dataStoreService.createItem(res);
        this.dataStoreService.refreshDataStore();
        this.notificationService.success('Datensatz wurde angelegt');
      },
      err => {
        this.notificationService.error('Datensatz konnte nicht gespeichert werden');
        console.log('Error: Datensatz konnte nicht gespeichert werden. ' + err);
      }
    );
    return obs;
  }

  updateItem(item: SelectionListItem, oldValue: string): Observable<any> {
    const obs = this.backendService.updateSelectionListItem(item, oldValue);
    obs.subscribe(() => {
        this.dataStoreService.updateItem(item);
        this.notificationService.success('Datensatz wurde geändert');
        this.loadAll();
      },
      err => {
        this.notificationService.error('Datensatz konnte nicht gespeichert werden');
        console.log('Error: Datensatz konnte nicht gespeichert werden. ' + err);
      }
    );
    return obs;
  }

  deleteItem(item: SelectionListItem): Observable<any> {
    const obs = this.backendService.deleteSelectionListItem(item);
    obs.subscribe(() => {
        this.dataStoreService.deleteItem(item);
        this.notificationService.success('Datensatz wurde gelöscht');
        this.loadAll();
        // this._selectionListItems.next(Object.assign({}, this.dataStore).selectionListItems);
      },
      err => {
        this.notificationService.error('Datensatz konnte nicht gelöscht werden');
        console.log('Error: Datensatz konnte nicht gelöscht werden. ' + err);
      }
    );
    return obs;
  }

  getTopics(): Array<any> {
    return this.dataStoreService.getTopics();
  }

  getListItemsByTopic(topic: string): Array<string> {
    const topicItems = this.filterItemsByTopic(topic);
    const itemList: string[] = [];
    topicItems.forEach(element => {
      itemList.push(element.itemValue);
    });
    return itemList;
  }

  private filterItemsByTopic(topic: string): Array<SelectionListItem> {
    return this.dataStoreService.filterItemsByTopic(topic);
  }

  getListItemsDependingOnValue(value: string): Array<string> {
    const items = this.filterItemsDependingOnValue(value);
    return this.getStringArrayFromSelectionItems(items);
  }

  getStringArrayFromSelectionItems(items: SelectionListItem[]): Array<string> {
    const itemList: string[] = [];
    items.forEach(element => {
      itemList.push(element.itemValue);
    });
    return itemList;
  }

  private filterItemsDependingOnValue(value: string): Array<SelectionListItem> {
    return this.dataStoreService.filterItemsDependingOnValue(value);
  }

  getListItemsDependingOnTopic(topic: string): Array<string> {
    const items = this.filterItemsDependingOnTopic(topic);

    const list: string[] = [];
    items.forEach(element => {
      list.push(element.itemTopic);
    });
    return list;
  }

  private filterItemsDependingOnTopic(topic: string): Array<SelectionListItem> {
    return this.dataStoreService.filterItemsDependingOnTopic(topic);
  }
}
