<div id="messages">
<div class="row">
  <div class="col-md-12">
      <div class="card">
          <div class="toolbar">
            <div class="title">
              <h3>
                Nachrichten | 
                  <span *ngIf="messageContainer == 'Unread'">Ungelesen</span>
                  <span *ngIf="messageContainer == 'Inbox'">Eingang</span>
                  <span *ngIf="messageContainer == 'Outbox'">Ausgang</span>
              </h3>
            </div>  

            <div class="btn-bar">
                <span title="Neue Nachricht" class="btn-icon message-new light" (click)="openModal('createMessageModal', 'Neue Nachricht')"></span>
                <span title="Ausgang" class="btn-icon message-outbox light" (click)="loadMessages('Outbox')"></span>
                <span title="Eingang" class="btn-icon message-inbox light" (click)="loadMessages('Inbox')"></span>
                <span title="Ungelesen" class="btn-icon message-unread light" (click)="loadMessages('Unread')"></span>
                <span title="Aufgaben" class="btn-icon tasks light" (click)="loadMessages('Unread')"></span>
                <span title="Löschen" class="btn-icon trash-small light" (click)="bulkDeleteMessage()" *ngIf="bulkMode"></span>
            </div>
            <!--
            <div class="btn-bar">
              <div class="btn icon" (click)="bulkDeleteMessage()" *ngIf="bulkMode">
                  <span class="icon trash-small light">Löschen</span>
                
              </div>
              <div class="btn icon" (click)="loadMessages('Unread')">
                  <span class="icon tasks light">Aufgaben</span>
                
              </div>
              <div class="btn icon" (click)="loadMessages('Unread')">
                  <span class="icon message-unread light">Ungelesen</span>
                
              </div>
              <div class="btn icon" (click)="loadMessages('Inbox')">
                  <span class="icon message-inbox light">Eingang</span>
                 
              </div>
              <div class="btn icon" (click)="loadMessages('Outbox')">
                  <span class="icon message-outbox light">Ausgang</span>
                 
              </div>
              <div class="btn icon">
                  <span class="icon message-new light" (click)="openModal('createMessageModal', 'Neue Nachricht')">Neu</span>
                 
              </div>
          </div>
          -->
              
          </div> 
          <div class="content">
            <div>
              
            </div>
          
            <div *ngIf="messages.length == 0">
              <h3 style="text-align: center">Keine Nachrichten</h3>
            </div>
          
            <div *ngIf="messages.length > 0">
              <table class="table" style="cursor: pointer">
                <thead>
                <tr>
                  <th *ngIf="messageContainer != 'Unread'"></th>
                  <th style="width: 20%">
                    <span *ngIf="messageContainer == 'Unread'">Von</span>
                    <span *ngIf="messageContainer == 'Inbox'">Von</span>
                    <span *ngIf="messageContainer == 'Outbox'">An</span>
                  </th>
                  <th style="width: 40%">Nachricht</th>
                  <th style="width: 20%">
                      <span *ngIf="messageContainer == 'Unread'">Erhalten</span>
                      <span *ngIf="messageContainer == 'Inbox'">Erhalten</span>
                      <span *ngIf="messageContainer == 'Outbox'">Gesendet</span>
                  </th>
                  <th style="width: 20%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let message of messages">
                  <td *ngIf="messageContainer != 'Unread'">
                      <input type="checkbox" (click)="checkboxChecked(message.id, $event)" class="checkbox" id="{{message.id}}" name="{{message.id}}">
                      <label for="{{message.id}}"></label>
                  </td>
                  <td (click)="setConversation(message.recipientId)">
                    <div *ngIf="messageContainer != 'Outbox'">
                          <!--<img src={{message?.senderPhotoUrl}} class="img-circle rounded-circle mr-1">-->
                          <span>{{message.senderFullName}}</span>
                    </div>
                    <div *ngIf="messageContainer == 'Outbox'">
                          <!--<img src={{message?.recipientPhotoUrl}} class="img-circle rounded-circle mr-1">-->
                          <span>{{message.recipientFullName}}</span>
                    </div>
                  </td>
                  <td (click)="setConversation(message.recipientId)"><strong>{{message.content}}</strong></td>
                  <td (click)="setConversation(message.recipientId)">{{message.messageSent | date: 'dd.MM.yyyy  HH:mm'}} Uhr</td>
                  <td>
                    <div class="td-actions">
                      <button (click)="deleteMessage(message.id)" class="btn danger btn-table"><span class="btn-icon trash light"></span></button>
                    </div>
                    <!--<button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteMessage(message.id)">Delete</button>-->
                  </td>
                </tr>
              </tbody>
              </table>
          
            </div>
          
          <!--
          <div class="d-flex justify-content-center">
              <app-pagination [boundaryLinks]="true"
                          [totalItems]="pagination.totalItems"
                          [itemsPerPage]="pagination.itemsPerPage"
                          [(ngModel)]="pagination.currentPage"
                          (pageChanged)="pageChanged($event)"
                        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
            </app-pagination>
            </div>
          -->
          </div> 
          
      
      </div>   <!-- end card -->
  </div>   <!-- end col -->

</div>   <!-- end row -->
</div>
<app-conversation *ngIf="conversationMode" [recipientId]="conversationPartner"></app-conversation>

<app-modal id="createMessageModal">
  <div class="body">Here is the content</div>
    <div class="actions"> 
        <div > 
          <button (click)="closeModal('createMessageModal');" class="btn btn clear">Cancel</button>
          <button type="submit" name="submit" value="Speichern" class="btn btn-primary" id="btn-user-info-save">Save</button>
        </div>
      </div>   <!-- end form-actions -->
</app-modal>
