  <div class="row">
    <div class="col-md-4 col-xs-1"></div>
    <div class="col-md-4 col-xs-10">
      <div id="login-component">
        <div class="card">
            <div class="toolbar">
              <div class="title">
                <h3 i18n>Bitte anmelden</h3>
              </div>   <!-- end title -->
            </div>   <!-- end toolbar -->

              <form [formGroup]="form" (ngSubmit)="login()"> 
                <input type='hidden' name='' value='' /> 
                <div class="form-fields-wrapper"> 
                  <div class="form-field-container" id="form-item-name"> 
                    <label for="id_name">Benutzername</label> 
                    <input type="email" autocomplete="email" formControlName="username" maxlength="50" class="form-control"/> 
                  </div> 
                  <div class="form-field-container" id="form-item-password"> 
                    <label for="id_password">Passwort</label> 
                    <input type="password" formControlName="password" maxlength="50" class="form-control" />
                    <small class="hint pointer" routerLink="/reset-password">Passwort vergessen?</small>
                  </div> 
                </div>   <!-- end form-fields-wrapper -->
                <div class="form-actions"> 
                  <button [disabled]="!form.valid" i18n type="submit" class="btn btn-primary login-btn" id="LoginBtn">Anmelden</button>
                </div>   <!-- end form-actions -->
              </form> 
              <!--
              <div class="divider"></div>
              <button i18n="Log in Microsoft" value="Speichern" class="btn clear login-btn" id="MicrosoftLoginBtn">Mit Microsoft-Account anmelden</button>
              -->
              <input type="hidden" name="ignore_warnings" value="False" id="id_ignore_warnings" /> 
        </div>   <!-- end card -->
      </div>   <!-- end login-component -->
    </div>    <!-- end col -->
    <div class="col-md-4 col-xs-1"></div>
    </div>