import { Pipe, PipeTransform } from '@angular/core';
import { sortByKeys } from '../utils/sort-by-keys/sort-by-keys';

@Pipe({
  name: 'sortByKeys'
})
export class SortByKeysPipe implements PipeTransform {
  public transform(value: any[], ...keys: string[]): any[] {
      return sortByKeys<any>(value.slice(), ...keys);
  }
}