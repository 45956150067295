import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first, switchMap } from 'rxjs/operators';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private notificationService: NotificationService) { }

  checkForUpdate(): void {
    if (this.swUpdate && this.swUpdate.isEnabled) {
      this.appRef.isStable.pipe(
          first(isStable => isStable === true),
          switchMap(() => this.swUpdate.available),
      ).subscribe(() => {
        this.installUpdate();
      });
    }
  }

  private installUpdate(): void {
    this.swUpdate.activateUpdate().then(() => {
     this.notificationService.confirm('Update installiert', 'Eine neue Version von Mojura wurde installiert. Jetzt neu laden.', ()=>{ window.location.reload(); }, ()=>{ this.notificationService.message('Neustart verschoben') });
    });
  }
}