import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {


  private currentSidebar: BehaviorSubject<string> = new BehaviorSubject<string>('basic');
  currentSidebar$ = this.currentSidebar.asObservable();

  sidebar: any = document.getElementById('sidemenu');
  sidebarContentWrapper: any = document.getElementById('content-wrapper');

  // Available sidebar contents
  gasManagementSidebar: any  = document.getElementById('GasManagementSidebar');
  administrationSidebar: any  = document.getElementById('AdministrationSidebar');
  basicSidebar: any = document.getElementById('BasicSidebar');

  constructor() { }

   // Change sidebar content
  setCurrentSidebar(sidebar: string) {
  this.currentSidebar.next(sidebar);
  }

  showSidebar() {
      if (document.getElementById('sidemenu').classList.contains('visible')) {
        document.getElementById('sidemenu').classList.add('visible');
      }
      if (document.getElementById('content-wrapper').classList.contains('right')) {
        document.getElementById('content-wrapper').classList.add('right');
      }
    }

  toggleSidebar() {
      document.getElementById('sidemenu').classList.toggle('visible');
      document.getElementById('content-wrapper').classList.toggle('right');
    }

  hideSidebar() {
      if (document.getElementById('sidemenu').classList.contains('visible')) {
        document.getElementById('sidemenu').classList.remove('visible');
      }
      if (document.getElementById('content-wrapper').classList.contains('right')) {
        document.getElementById('content-wrapper').classList.remove('right');
      }
    }


    // Remove left nav-items
  removeNavElements() {
      const sidemenu = document.getElementById('sidemenu');
      const navleft = document.getElementById('navbar-nav-left');

      if (sidemenu.classList.contains('visible')) {

        sidemenu.classList.toggle('visible');
        document.getElementById('content-wrapper').classList.toggle('right');
      }
      navleft.classList.add('hidden');
    }

}
