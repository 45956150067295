import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  confirm(title: string, message: string, oKCallback: () => any,  cancelCallback: () => any ): void {
    // tslint:disable-next-line: only-arrow-functions
    alertify.confirm(title, message, oKCallback, cancelCallback )}

  success(message: string): void {
    alertify.success(message);
  }

  error(message: string): void {
    alertify.error(message);
  }

  warning(message: string): void {
    alertify.warning(message);
  }

  message(message: string): void {
    alertify.message(message);
  }
}
