import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './messages.component';
import { MessageComposeComponent } from '../messages/message-compose/message-compose.component';
import { ConversationComponent } from './conversation/conversation.component';
import { ModalModule } from 'src/app/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MessagesComponent,
    MessageComposeComponent,
    ConversationComponent
  ]
})
export class MessagesModule { }
