import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageViewerService } from 'src/app/services/image-viewer/image-viewer.service';

@Component({
  selector: 'tm-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss']
})
export class ImageBoxComponent implements OnInit {

  @Input() disabled : boolean = false;
  @Input() imageUrl : string = null;
  @Output() deleteImage = new EventEmitter();

  constructor(private imageViewerService: ImageViewerService) {
    
   }

  ngOnInit() {
  }

  onSelect() {
    this.imageViewerService.setImageUrl(this.imageUrl)
  }

  deleteImg() {
    this.deleteImage.next();
  }

}
