<div class="row">
  <div class="col-md-4 col-xs-1"></div>
  <div class="col-md-4 col-xs-10">
    <div id="login-component">
      <div class="card">
          <div class="toolbar">
            <div class="title">
              <h3>Passwort zurücksetzen</h3>
            </div>   <!-- end title -->
          </div>   <!-- end toolbar -->
            <form [formGroup]="form" (ngSubmit)="resetPassword()"> 
              <input type='hidden' name='' value='' /> 
              <div class="form-fields-wrapper"> 
                <p style="text-align: justify; padding: 0 8px 8px;">Wenn Ihnen das Passwort Ihres Accounts nicht mehr bekannt ist, können Sie dieses jederzeit zurücksetzen. Geben Sie dazu einfach Ihren Benutzernamen ein. Ist ein entsprechender Benutzername hinterlegt, erhalten Sie ein neues Passwort per Mail.</p>
                <hr>
                <div class="form-field-container" id="form-item-name"> 
                  <label for="id_name">Benutzername</label> 
                  <input type="email" formControlName="username" maxlength="50" class="form-control" required/> 
                </div> 
                <div class="p-8"></div>
                <div class="center">
                  <ngx-recaptcha2 style="display: inline-block;" #captchaElem [siteKey]="siteKey" formControlName="recaptcha"></ngx-recaptcha2>
                </div>
              </div>   <!-- end form-fields-wrapper -->
              <div class="form-actions button-bar"> 
                <div class="submit-row"> 
                  <button class="btn btn clear" routerLink="/login">Abbrechen</button>
                  <button type="submit" class="btn btn-primary">Passwort zurücksetzen</button>
                </div>
              </div>   <!-- end form-actions -->
             
            </form> 
            <input type="hidden" name="ignore_warnings" value="False" id="id_ignore_warnings" /> 
      </div>   <!-- end card -->
    </div>   <!-- end login-component -->
  </div>    <!-- end col -->
  <div class="col-md-4 col-xs-1"></div>
  </div>