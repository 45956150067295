import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  theme = 'Default';
  themeClass = 'theme--default';

constructor() {
  if (!localStorage.getItem('appTheme'))
  {
    localStorage.setItem('appTheme', 'Default');
    this.theme = 'Default';
  } else {
    this.theme = localStorage.getItem('appTheme');
  }
  this.themeClass = this.setThemeClass(this.theme);
}

  initializeColorTheme(): void {
    if (this.theme.toLowerCase() === 'default') {
        this.setThemeClass(this.theme);
    }
    if (this.theme.toLowerCase() === 'dark') {
      this.setThemeClass(this.theme);
    }
  }

  changeColorTheme(theme: string): void {
    this.setThemeClass(theme);
    localStorage.setItem('appTheme', theme);
  }

  setThemeClass(theme = 'Default'): any {
    this.themeClass = 'theme--' + theme.toLowerCase();
  }
}