<div class="container-fluid">
  <div class="row">
      <div class="col-md-3">
        </div>
    <div class="col-md-6">
        <div class="card">
            <div class="toolbar">
              <div class="title">
                <h3 i18n="@@3254af9acb61fe6a3c6577be47bbe5c382890ce1">Einstellungen</h3>
              </div>  
                
            </div> 
            <div class="content">
                <div class="info-box">
                    <form [formGroup]="settingsForm" (ngSubmit)="saveSettings()"> <input type='hidden' name='' value='' /> 
                        <div class="form-fields-wrapper"> 
                          <div *appUserHasRoles="['SuperAdmin']" class="form-field-container" id="form-item-locale"> 
                            <label i18n for="language">Sprache</label> 
                            <select name="language" formControlName="language" class="form-control" id="language"> 
                              <option disabled value="{{selectedLanguage}}">{{selectedLanguage}}</option>
                              <option value="en">English</option> 
                              <option value="de">Deutsch</option> 
                            </select> 
                          </div>
                          <div class="form-field-container" id="form-item-locale"> 
                            <label i18n for="color-theme">Theme</label> 
                            <select name="color-theme" formControlName="colorTheme" class="form-control" id="color-theme"> 
                              <option disabled value="{{selectedColorTheme}}">{{selectedColorTheme}}</option>
                              <option value="Default">Default</option> 
                              <option value="Dark">Dark</option> 
                            </select> 
                          </div>
                        </div>   <!-- end form-fields-wrapper -->
                        <div class="form-actions button-bar"> 
                          <div class="submit-row"> 
                            <button  type="submit" value="Speichern" class="btn btn-primary" id="btn-user-info-save">Speichern</button>
                          </div>
                        </div>   <!-- form-actions -->
                        <input type="hidden" name="ignore_warnings" value="False" id="id_ignore_warnings" /> 
                      </form>
              </div>
            </div> 
            
        
        </div>
    </div>
    <div class="col-md-3">
    </div>

  </div>
</div>