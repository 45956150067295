import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/models/message';
import { Pagination, PaginatedResult } from 'src/app/models/pagination';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/modal';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html'
})
export class MessagesComponent implements OnInit {
  messages: Message[];
  pagination: Pagination;
  messageContainer = 'Unread';
  conversationMode: boolean;
  bulkMode: boolean;
  messagesToDelete: number[];
  conversationPartner: number;

  constructor(private messageService: MessageService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private modalService: ModalService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.conversationMode = false;
    this.messagesToDelete = [];
    this.bulkMode = false;
    this.route.data.subscribe(data => {
      this.messages = data['messages'].result;
      this.pagination = data['messages'].pagination;
    });
    this.conversationPartner = 1;
  }

  loadMessages(boxtype: string) {
    this.messageContainer = boxtype;
    this.messageService.getMessages(this.authService.decodedToken.nameid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.messageContainer)
      .subscribe((res: PaginatedResult<Message[]>) => {
        this.messages = res.result;
        this.pagination = res.pagination;
      }, err => {
        this.notificationService.error('Nachrichten konnten nicht geladen werden');
        console.log('Error: Nachrichten konnten nicht geladen werden. ' + err);
      });
  }

  deleteMessage(id: number) {
    this.notificationService.confirm('Nachricht löschen', 'Sind Sie sicher, dass Sie die Nachricht löschen möchten?', () => {
      this.messageService.deleteMessage(id, this.authService.decodedToken.nameid).subscribe(() => {
        this.messages.splice(this.messages.findIndex(m => m.id === id), 1);
        this.notificationService.success('Nachricht wurde gelöscht!');
      }, err => {
        this.notificationService.error('Fehler beim Löschen der Nachricht!');
        console.log('Error: Fehler beim Löschen der Nachricht! ' + err);
      });
    }, ()=>{ });
  }

  bulkDeleteMessage() {
    const messages = this.messagesToDelete;
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadMessages(this.messageContainer);
  }

  setConversation(partner: number) {
    this.conversationPartner = partner;
    this.conversationMode = true;
  }

  checkboxChecked(id: number, event) {

    if ( event.target.checked ) {
      this.messagesToDelete.push(id);
    } else {
      for ( let i = 0; i < this.messagesToDelete.length; i++) {
        if ( this.messagesToDelete[i] === id) {
          this.messagesToDelete.splice(i, 1);
        }
     }
    }
    if (this.messagesToDelete.length > 0) {
      this.bulkMode = true;
    } else {
      this.bulkMode = false;
    }
  }

  openModal(id: string, title: string) {
    this.modalService.open(id, title);
}

  closeModal(id: string) {
    this.modalService.close(id);
}
}