<div class="row">
    <div id="table-col" class="col-md-3"></div>
    <div id="table-col" class="col-md-6">
      <div class="card">
        <div class="toolbar">
          <div class="title">
            <h3>Profil</h3>
          </div>  
        </div>   <!-- end toolbar -->
        <div class="content">
           
            <tm-tabs>
                <tm-tab [tabTitle]="'Allgemein'">
                  <form [formGroup]="userProfileForm" > 
                    <input type='hidden' name='' value='' /> 
                    <div class="form-fields-wrapper"> 
                      <div class="form-field-container"> 
                        <label for="givenname">Vorname</label> 
                        <input type="text" value="{{user?.givenName}}"
                                [ngClass]="{'is-invalid' : userProfileForm.get('givenname').errors && userProfileForm.get('givenname').touched}"
                                formControlName="givenname" 
                                class="form-control"/> 
                      </div> 
                      <div class="form-field-container"> 
                        <label for="surname">Nachname</label> 
                        <input type="text" value="{{user?.surname}}"
                                [ngClass]="{'is-invalid' : userProfileForm.get('surname').errors  && userProfileForm.get('surname').touched}"
                                formControlName="surname"
                                class="form-control"/> 
                      </div> 
                      <div class="form-field-container"> 
                        <label for="username">Benutzername | E-Mail</label> 
                        <input type="text" value="{{user?.username}}"
                                [ngClass]="{'is-invalid' : userProfileForm.get('username').errors  && userProfileForm.get('username').touched}"
                                formControlName="username"
                                class="form-control"/> 
                      </div>
                    </div>   <!-- end form-fields-wrapper -->
                    <div class="form-actions button-bar light"> 
                      <div style="visibility: hidden" class="submit-row"> 
                        <button  type="cancel" name="cancel" value="Abbrechen" class="btn btn clear">Abbrechen</button>
                        <button type="submit" name="submit" value="Speichern" class="btn btn-primary">Benutzer speichern</button>
                      </div>
                    </div>   <!-- form-actions -->
                    <input type="hidden" name="ignore_warnings" value="False" id="id_ignore_warnings" /> 
                  </form>
                </tm-tab>

                <tm-tab [tabTitle]="'Passwort ändern'">
                  <form [formGroup]="changePasswordForm"> 
                    <input type='hidden' name='' value='' /> 
                    <div class="form-fields-wrapper"> 
                      <div class="form-field-container"> 
                          <label for="oldPassword">Altes Passwort</label> 
                          <input type="password"
                                  [ngClass]="{'is-invalid' : changePasswordForm.get('oldPassword').errors  && changePasswordForm.get('oldPassword').touched}"
                                  formControlName="oldPassword"
                                  class="form-control"/> 
                          <div class="invalid-feedback" *ngIf="changePasswordForm.get('oldPassword').hasError('required')  && changePasswordForm.get('oldPassword').touched">Bitte geben Sie ihr altes Passwort ein</div>
                        </div> 

                      <div class="form-field-container"> 
                        <label for="newPassword">Neues Passwort</label> 
                        <input type="password"
                                [ngClass]="{'is-invalid' : changePasswordForm.get('newPassword').errors  && changePasswordForm.get('newPassword').touched}"
                                formControlName="newPassword"
                                class="form-control"/> 
                        <div class="invalid-feedback" *ngIf="changePasswordForm.get('newPassword').hasError('required')  && changePasswordForm.get('newPassword').touched">Bitte geben Sie ein Passwort ein</div>
                        <div class="invalid-feedback" *ngIf="changePasswordForm.get('newPassword').hasError('minlength')  && changePasswordForm.get('newPassword').touched">Das Passwort muss mindestens 8 Zeichen haben</div>
                        <div class="invalid-feedback" *ngIf="changePasswordForm.get('newPassword').hasError('weakPasswordStength')  && changePasswordForm.get('newPassword').touched">Das Passwort muss Großbuchstaben, Kleinbuchstaben und Zahlen beinhalten!</div>
                      </div> 
                      <div class="form-field-container"> 
                        <label for="repeatNewPassword">Neues Passwort wiederholen</label> 
                        <input type="password" 
                                [ngClass]="{'is-invalid' : changePasswordForm.get('repeatNewPassword').errors  && changePasswordForm.get('repeatNewPassword').touched}"
                                formControlName="repeatNewPassword"
                                class="form-control"/> 
                        <div class="invalid-feedback" *ngIf="changePasswordForm.get('repeatNewPassword').hasError('required')  && changePasswordForm.get('repeatNewPassword').touched">Bitte wiederholen Sie das Passwort</div>
                        <div class="invalid-feedback" *ngIf="changePasswordForm.get('repeatNewPassword').hasError('NoPasswordMatch')  && changePasswordForm.get('repeatNewPassword').touched">Die Passwörter stimmen nicht überein</div>
                      </div> 
                    
                    </div>   <!-- end form-fields-wrapper -->
                    <div class="form-actions button-bar light"> 
                      <div class="submit-row"> 
                        <button type="cancel" name="cancel" value="Abbrechen" class="btn btn clear">Abbrechen</button>
                        <button type="submit" name="submit" value="Speichern" class="btn btn-primary" (click)="changePassword()">Passwort ändern</button>
                      </div>
                    </div>   <!-- form-actions -->
                    <input type="hidden" name="ignore_warnings" value="False" id="id_ignore_warnings2" /> 
                  </form>
                </tm-tab>
            </tm-tabs>
            
        </div>   <!-- end content -->
      </div>   <!-- end card -->
    </div>
    <div id="table-col" class="col-md-3"></div>
  </div>
